import styled from 'styled-components';
import {screenSize} from '../../constants/styleConstants';
import { tokens } from '@snsw/react-component-library';

export const AccordionContainer = styled.div`
  display: block;
  width: 100%;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  border: 1px solid ${tokens.colors.grey.geyser};
  height: auto;
  padding: 8px 14px 0 30px;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.07) 0 2px 8px 0;
  @media only screen and (max-width: ${screenSize.tablet}) {
    padding: 8px 4px 8px 20px;
  }
  &::before {
    content: '';
    position: absolute;
    border-radius: 4px 4px 0px 0px;
    left: 0;
    right: 0;
    top: 0;
    height: 5px;
    background-color: ${tokens.colors.brand.snswSecondaryBlue};
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0.3s ease;
  }

  &:hover::before {
    transform: scaleY(1);
  }

  &.openAccordion::before {
    transform: scaleY(1);
  }
`;

export const AccordionHeaderMainSection = styled.div`
  display: flex;
  align-items: center;
  column-gap: 24px;

  @media only screen and (max-width: ${screenSize.tablet}) {
    flex-wrap: wrap;
  }
`;

export const AccordionHeader = styled.div`
  padding-bottom: 24px;
  @media only screen and (max-width: ${screenSize.tablet}) {
    padding-bottom: 16px;
  }
`;

export const ChevronIcon = styled.span`
  padding: 16px 16px 0 16px;
  margin-left: auto;
  cursor: pointer;
`;

export const AccordionContent = styled.div`
  padding: 32px 16px 32px 0;
    > * {
      &:first-child {
         margin-top: 0px;
      }
    }
    > * {
      &:last-child {
         margin-bottom: 0px;
      }
    }
    & > ul {
      @media only screen and (max-width: 767px) {
        margin: 1rem 0rem 1rem 1.5rem
    }

    }
    & > ul > li {
        line-height: 1rem
    }
  
`;

export const AccordionDivider = styled.hr`
  margin: 0 -14px 0 -30px;
  border-top: 1px solid ${tokens.colors.grey.geyser};
  @media only screen and (max-width: ${screenSize.tablet}) {
    margin: 0 -4px 0 -20px;
  }
`;

export const ServiceTitle = styled.h3`
  margin: 0;
  font-size: 18px;
  white-space: nowrap;
`;

export const ServiceActionBox = styled.div`
  flex:  0 1 66.66%; /* 66.66% width of the parent (8 bootstrap columns), shrink but don't grow initially */
  @media only screen and (max-width: ${screenSize.tablet}) {
    flex-basis: 100%;
    padding-right: 16px;
  }
`;

export const ServiceNameBox = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  padding-top: 16px;
  height: 48px;
  flex: 1 0 25%; /* Set the width of the title to a 1/4 of the parent (3 bootstrap columns), grow but don't shrink initially */
  min-width: 235px;
`;

export const PreferenceDescription = styled.p`margin: 0 0 0 0;`;

export const PreferenceTitleContainer = styled.div`
        display: flex;
        column-gap: 24px; 
        flex-direction: row;
        @media only screen and (max-width: ${screenSize.tablet}) {
            flex-direction: column-reverse;
            row-gap: 16px;
        }
    `;

