import styled from 'styled-components';
import {screenSize} from '../../constants/styleConstants';

export const DetailsContainer = styled.div`
        /* Common styles for elements here */
        display: flex;
        flex-wrap: wrap;
        column-gap: 3.125rem;
        row-gap: 0.8125rem; // Because of the span padding 0.1875rem used to achieve the correct spacing of 1rem
        /* Default styling for desktop devices */
        margin: 1.5rem 0;
        flex-direction: row;
        align-items: center;
        /* Styling for mobile and tablet devices */
        @media only screen and (max-width: ${screenSize.tablet}) {
            margin: 1rem 0;
            flex-direction: column;
            align-items: flex-start;
        }
    `;
export const Details = styled.div`
        /* Common styles for elements here */
        display: flex;
        flex-wrap: wrap;
        column-gap: 3.125rem;
        row-gap: 0.8125rem; // Because of the span padding 0.1875rem used to achieve the correct spacing of 1rem
        /* Default styling for desktop devices */
        flex-direction: row;
        align-items: center;
        /* Styling for mobile devices */
        @media only screen and (max-width: ${screenSize.tablet}) {
            flex-direction: column;
            align-items: flex-start;
        }
    `;
export const DetailsElement = styled.div`
        display: flex;
        column-gap: 0.625rem;
    `;

export const Detail = styled.div`
        display: flex;
        flex-wrap: wrap;
        column-gap: 0.25rem;
    `;