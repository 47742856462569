import styled from 'styled-components';
import {screenSize} from '../../constants/styleConstants';
import {HorizontalRule, tokens} from '@snsw/react-component-library';

export const VisuallyHiddenDiv = styled.div`
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;

export const SaveErrorDiv = styled.div`
  display: flex;
  //align-items: center; /* Center items vertically */
  padding-top: 32px;
  color: ${tokens.colors.brand.snswPrimaryRed};

  @media only screen and (max-width: ${screenSize.tablet}) {
    padding-top: 16px;
  }

  svg {
    margin-right: 12px;
    padding-top: 4px;
    flex-shrink: 0; /* Prevent the SVG from shrinking */
    height: 20px;
  }
`;

export const ContactDivide = styled(HorizontalRule)`
    @media only screen and (max-width: ${screenSize.tablet}) {
        margin: 32px 0;
    }
`;