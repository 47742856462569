import React from 'react';
import PropTypes from 'prop-types';
import {SaveButton, SavePreferenceContainer} from './saveFooter.styled';
import {getTagManagerArgs} from '../../googleAnalyticsUtils';
import TagManager from 'react-gtm-module';

const handleSaveButtonClick = savePreferences => () => {
    TagManager.dataLayer(getTagManagerArgs('click-button', 'digital communications',
        'save'));
    savePreferences();
};

const SaveFooter = (props) => {

    return (
        <SavePreferenceContainer data-testid='save-footer'>
            <SaveButton data-testid='save-button' onClick={ handleSaveButtonClick(props.savePreferences) }
                aria-label='Save Preferences'>{props.saveButtonLabel}</SaveButton>
        </SavePreferenceContainer>
    );
};

SaveFooter.propTypes = {
    savePreferences: PropTypes.func,
    saveButtonLabel: PropTypes.string,
};

export default SaveFooter;