import styled from 'styled-components';
import {tokens} from '@snsw/react-component-library';
import Button from '@snsw/react-component-library/build/Components/Button/Button';
import {screenSize} from '../../constants/styleConstants';

export const PreferencesItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    @media only screen and (max-width: ${screenSize.tablet}) {
        gap: 16px;
    }
`;
export const BulkExpandContainer = styled.div`
    display: flex;
    justify-content: center;
    align-self: flex-end;
    gap: 16px;
`;
export const BulkExpandButton = styled(Button)`
    text-decoration-line: underline;
    color: ${tokens.colors.brand.snswSecondaryBlue};
    cursor: pointer;
    &.disabled {
      color: ${tokens.colors.grey.shuttle} !important;
    }
`;