import axios from 'axios';
import utilities from './utilities';

const config = {
    url: '/api/pref/me/v2',
    headers: {
        'Content-Type': 'application/json'
    },
    withCredentials: true
};

const getPreferenceConfigs = async () => {
    return axios.get(config.url, config)
        .then(response => {
            utilities.setCSRFToken(response.headers['x-csrf-token']);
            return response;
        })
        .catch(error => {
            console.log(`caught error: ${error}`);
            if(error.response.status === 401) {
                window.open('/api/oauth2/authorization/tgo', '_self');
            }
        });
};

const updatePreferenceConfigs = async (consentData) => {
    config.headers['x-change-reason'] = 'MYACCOUNT_PREFERENCES_PAGE';
    return axios.patch(config.url, consentData, config)
        .then(response => {
            utilities.setCSRFToken(response.headers['x-csrf-token']);
            return response;
        })
        .catch(error => {
            console.log(`caught error: ${error}`);
            if (error.response.status === 401) {
                window.open('/api/oauth2/authorization/tgo', '_self');
            }
            throw error;
        });
};

export const apiServices = {
    getPreferenceConfigs,
    updatePreferenceConfigs,
};