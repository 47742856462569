import {AlertNotification} from '../alert/Alert.styled';
import React from 'react';
import PropTypes from 'prop-types';

export const SavedPreferencesAlert = ((props) => {

    return (
        <><AlertNotification headingLevel='h5' style={ {marginBottom: 0, marginTop: 24} }
            variant='success' title='Preferences saved'
            role='alert'>
            <div data-testid='update-information'>
                <div>
                    <p>You’ve updated to:</p>
                </div>
                <p style={ {lineHeight: 1, marginBottom: 0} }><br/></p>
                {props.optInArr.length > 0 && <div>
                    <ul>{props.optInArr.map((service, index) => {
                        const endSymbol = (index === props.optInArr.length-1 && props.optOutArr.length === 0) ? '.' : '';
                        return (<li key={ service }>{`digital notifications for ${service}${endSymbol}`}</li>);
                    })}
                    </ul>
                </div>}
                {props.optOutArr.length > 0 && <div>
                    <ul>{props.optOutArr.map((service, index) => {
                        const endSymbol = (index === props.optOutArr.length-1) ? '.' : '';
                        const optOutDesc = (service === 'News and updates') ? 'no notifications' : 'paper notifications';
                        return (<li key={ service }>{`${optOutDesc} for ${service}${endSymbol}`}</li>);
                    }
                    )}
                    </ul>
                </div>}
                <p style={ {lineHeight: 1, marginBottom: 0} }><br/></p>
                <div data-testid='email-information'>
                    <p>We’ve emailed you a summary. Check your junk folder if you do not see it in your inbox.</p>
                </div>
            </div>
        </AlertNotification></>
    );
});

SavedPreferencesAlert.propTypes = {
    optInArr: PropTypes.array,
    optOutArr: PropTypes.array
};