import styled from 'styled-components';

export const CharactersLimitDiv = styled.div`
    text-align: right;
    font-size: 0.75rem;
    height: 1rem;
    line-height: 1rem;
    color: rgb(100, 105, 116);
    margin-top: 0.5rem;
    margin-bottom: 1rem;
`;

