import axios from 'axios';
import DOMPurify from 'dompurify';

export default {
    setCSRFToken: (token) => {
        axios.defaults.headers.common['x-csrf-token'] = token;
    },

    addComment: (comment, applicationName, sourceApplication, rating='0') => {
        const url = process.env.REACT_APP_ONEGOV_SUBMIT_URL;
        const params = new URLSearchParams();
        params.append('rating', rating);
        params.append('comments', comment);
        params.append('applicationName',applicationName);
        params.append('sourceApplication',sourceApplication);
        params.append('userAgent',navigator.userAgent);
        return fetch(url,{method:'POST',headers:{'Content-Type':'application/x-www-form-urlencoded'}, body:params.toString()});
    }
};

export function sanitize(targetPurifier) {
    targetPurifier.setConfig({
        ALLOWED_TAGS: ['b', 'a', 'p', 'br','ul','li','h5'],
        ALLOWED_ATTR: ['href', 'style', 'target', 'rel'],
        ALLOWED_URI_REGEXP: /(https?:\/\/(.+?\.)?service\.nsw.gov.au(\/[A-Za-z0-9\-\._~:\/\?#\[\]@!$&'\(\)\*\+,;\=]*)?)/i
    });
    targetPurifier.addHook('afterSanitizeAttributes', (node) => {
        if (node.tagName === 'A' && !node.getAttribute('target')) {
            node.setAttribute('target', '_blank');
            node.setAttribute('rel', 'noopener');
        }
    });
    return targetPurifier.sanitize;
};