import React from 'react';
import PreferencesItem from '../preferenceItem/PreferencesItem';
import Alert from '../alert/Alert';
import PropTypes from 'prop-types';
import {BulkExpandContainer, PreferencesItemContainer} from './ServicesBox.styled';
import Button from '@snsw/react-component-library/build/Components/Button/Button';

export const ServicesBox = ({preferenceConfigs, servicesError, serviceConsent, updateServiceConsent,
    expandedAccordionServices, updateExpandedAccordionState, changeAllAccordions}) => {

    return <div aria-labelledby={ 'servicesRegion' }>
        <h2 style={ { marginBottom: 0 } } id={ 'servicesRegion' }>Your preferences</h2>
        <div style={ { marginBottom: '1rem' } }>
            <p>Digital notifications are sent by email, SMS, push notification (if enabled) and/or MyServiceNSW Account Inbox.</p>
            <p>Paper notifications will be sent by post.</p>
        </div>
        { servicesError ? <Alert headingLevel='h3' alert={ servicesError }/> :
            (
                <PreferencesItemContainer>
                    <BulkExpandContainer>
                        <Button
                            disabled={ Object.values(expandedAccordionServices).every(value => value === true) &&
                                Object.keys(expandedAccordionServices).length === preferenceConfigs.length }
                            onClick={ (e) => { e.preventDefault(); changeAllAccordions(true); } }
                            variant='link'
                        >Open all</Button>
                        <Button
                            disabled={ Object.values(expandedAccordionServices).every(value => value === false) }
                            onClick={ (e) => { e.preventDefault(); changeAllAccordions(false); } }
                            variant='link'
                        >Close all</Button>
                    </BulkExpandContainer>
                    {preferenceConfigs.map((service) => (
                        <div key={ service.name }>
                            <PreferencesItem
                                serviceDescription={ service.description }
                                serviceTitle={ service.name }
                                alert={ service.alert }
                                optedIn={ serviceConsent[service.serviceCode] ?? service.optInStatus }
                                showOptedInStatusLabel={ service.optInStatus }
                                displayCheckbox={ service.displayCheckbox }
                                learnMoreLinkText={ service.learnMoreLinkText }
                                learnMoreLinkHref={ service.learnMoreLinkHref }
                                code={ service.serviceCode }
                                aboutService={service.aboutService}
                                updateServiceConsent={ updateServiceConsent }
                                isExpanded={ expandedAccordionServices[service.serviceCode] ?? false }
                                updateExpandedAccordionState={ updateExpandedAccordionState }
                                icon={ service.icon }
                                optInType={ service.optInType }
                            />
                        </div>
                    ))}
                </PreferencesItemContainer>
            )
        }
    </div>;
};

ServicesBox.propTypes = {
    preferenceConfigs: PropTypes.array,
    serviceConsent: PropTypes.object,
    servicesError: PropTypes.object,
    updateServiceConsent: PropTypes.func,
    expandedAccordionServices: PropTypes.object,
    updateExpandedAccordionState: PropTypes.func,
    changeAllAccordions: PropTypes.func
};