export function getTagManagerArgs(event, elementSection, elementName) {
    return {
        dataLayer: {
            'event': event,
            'element-section': elementSection ?? 'digital communications',
            'element-name': elementName
        }
    };
}

export function getTagManagerAlertView(elementName, optInArray, optOutArray) {
    let optIn = null;
    let optOut = null;

    if (optInArray != null) {
        optIn = optInArray.length === 1 ? optInArray[0] : optInArray.join('; ');
    }
    if (optOutArray != null) {
        optOut = optOutArray.length === 1 ? optOutArray[0] : optOutArray.join('; ');
    }

    return {
        dataLayer: {
            'event': 'alert-view',
            'element-section': 'digital communications',
            'element-name': elementName,
            'notification-opt-in': optIn,
            'notification-opt-out': optOut
        }
    };
}
